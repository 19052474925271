import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import Adviser from "./components/Adviser";
import Broker from "./components/Broker";
import Grid from "./components/Grid";
import Contact from "./components/Contact"
import Terms from "./components/Legal/Terms"

//TODO Web Template Studio: Add routes for your new pages here.
class App extends Component {
  render() {
    return (
      <React.Fragment>
        <NavBar />
        <Switch>
          <Redirect exact path = "/" to = "/Adviser" />
          <Route path = "/Adviser" component = { Adviser } />
          <Route path = "/Broker" component = { Broker } />
          <Route path = "/Grid" component = { Grid } /> 
          <Route path = "/Contact" component = { Contact } />
          <Route exact path = "/Terms" component = { Terms } />
        </Switch>
        <Footer />
      </React.Fragment>
    );
  }
}

export default App;
