import React, { Component } from 'react'




export default class BrokerClass extends Component {
    constructor(props) {
      super(props);
      this.state = {
      //  gridTextAssets: [],
        width: window.innerWidth,
      };


     }
    
 
  UNSAFE_componentWillReceiveProps () {
   // console.log('in will recieve props')
    window.addEventListener('resize', this.handleWindowSizeChange);

  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
   // console.log('in will mount')


  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  render() {

    // const { width } = this.state;
    // const isMobile = width <= 800;
    // const {
    //     rData,
    //   } = this.state;
 
    return (
        <div class="card border-info">
            <div class="card-header"> CIK Number: {this.props.brokerId}</div>
                <div class="card-body">
                    <h6 class="card-title text-center text-info"><strong>{this.props.broker} </strong></h6> 
                         <p><br></br></p>             
                         <p class="card-text">
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/' + this.props.street.toString() + '+' + this.props.city.toString() + '+' + this.props.state.toString()}> 
                               <u> {this.props.street} <br></br> {this.props.street2} <br></br> {this.props.city} {this.props.state} {this.props.zip} </u> </a> <br></br> {this.props.country} <br></br>
                               
                                 {this.props.phone}
                        </p>                        
                </div>

            <div class="card-footer">
                     <a target="_blank" rel="noopener noreferrer" href={('https://www.sec.gov/cgi-bin/browse-edgar?CIK=' + this.props.brokerId)} >
                        <u>See EDGAR Filings</u>  </a>
                
            </div>
        </div>
    )

  }

}