import React from "react";
import styles from "./footer.module.css";
//import seclogo from "./../../images/sec-logo3.png";
import appstorelogo from "./../../images/applestore129.png";
import googlelogo from "./../../images/googlelog129.PNG";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className="container-fluid">
        <div className="row justify-content-around">
          <div className="col-7 col-md-5">
            <h5 className={styles.title}>Registered Investment Adviser Directory</h5>
            <p className={styles.description}>
                    Broker Dealer Directory <br></br>
                    Data is provided by the 
                     <a target="_blank" rel="noopener noreferrer" href="https://sec.gov/">
                     <u> Securities and Exchange Commission </u>  
                    </a>.
                   </p>
                  <p  className={styles.description}>    Last updated: December 30, 2020. &emsp; 
                  
            </p>
          </div>
          <div className="col-5">
            <ul className="list-unstyled">
              <li  className="nav-item nav-link active">
              <a target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/hedge-fund-pages/id1481680017">
              <img src={appstorelogo}  alt="hedgefundpages.com" /> 
                </a>
              </li>
              <li  className="nav-item nav-link active">
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.flutter_hfp">
              <img src={googlelogo}  alt="hedgefundpages.com" /> 
                </a>
              </li>
              <li>
              <Link className="nav-item nav-link active" to="Adviser">
                  hedgefundpages.com
              </Link>
              </li>
              <li>
              <Link className="nav-item nav-link active" to="Terms">
                  Terms and Privacy Policy
              </Link>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
