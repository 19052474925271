import React, { Component } from 'react'

export default class Terms extends Component {

render() {
return (

    <div>

        <strong>TERMS OF SERVICE </strong>
        <p><br></br></p>
        Welcome 
        <p><br></br></p>
        Thanks for using our products and services (“Services”). The Services are provided hedgefundpages.com.
        By using our Services, you are agreeing to these terms. Please read them carefully.
        <p><br></br></p>
        Using our Services
        You must follow any policies made available to you within the Services.
        Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct.
        Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed in or along with our Services.
        Our Services display some that is not public information. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do.
        In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.
        Some of our Services are available on mobile devices. Do not use such Services in a way that distracts you and prevents you from obeying traffic or safety laws.

        <p><br></br></p>
        About Software in our Services
        When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available. Some Services may let you adjust your automatic update settings.
        Hedgefundpages.com gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by Hedgefundpages.com as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by Hedgefundpages.com, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission.
        Open source software is important to us. Some software used in our Services may be offered under an open source license that we will make available to you. There may be provisions in the open source license that expressly override some of these terms.
        <p><br></br></p>
        Modifying and Terminating our Services
        We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether.
        You can stop using our Services at any time, although we’ll be sorry to see you go. Hedgefundpages.com may also stop providing Services to you, or add or create new limits to our Services at any time.
        We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.
        Our Warranties and Disclaimers
        We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services.
        OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER HEDGEFUNDPAGES.COM NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES “AS IS”.
        SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
        <p><br></br></p>
        Liability for our Services
        WHEN PERMITTED BY LAW, HEDGEFUNDPAGES.COM, AND HEDGEFUNDPAGES.COM’S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.
        TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF HEDGEFUNDPAGES.COM, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIMS UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN).
        IN ALL CASES, HEDGEFUNDPAGES.COM, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
        Business uses of our Services
        If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify Hedgefundpages.com and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.
        <p><br></br></p>
        About these Terms
        We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. We’ll post notice of modified additional terms in the applicable Service. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service.
        If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict.
        These terms control the relationship between Hedgefundpages.com and you. They do not create any third party beneficiary rights.
        If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future).
        If it turns out that a particular term is not enforceable, this will not affect any other terms.
        The laws of California, U.S.A., excluding California’s conflict of laws rules, will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of or relating to these terms or the Services will be litigated exclusively in the federal or state courts of Santa Clara County, California, USA, and you and Hedgefundpages.com consent to personal jurisdiction in those courts.
        For information about how to contact Hedgefundpages.com, please visit our contact page.
        <p><br></br></p>
        Disclaimer
        This website is for informational purposes only and is not to be construed as a solicitation or an offer to buy or sell any securities or related financial instruments nor an endorsement of any particular investment advisor firm or individual. No warranty or guarantee of any kind, expressed or implied, is given regarding the accuracy, reliability, veracity, or completeness of the information provided here or by following links from this or any other page within this site, and under no circumstances will the author or service provider be held responsible, or liable for errors, or omissions resulting in any loss or damage caused or alleged to be caused by information contained in the material presented on the site, including but not limited to direct, indirect, incidental, special or consequential damages caused by using the information.
        <p><br></br></p>
        <strong> Privacy Policy</strong>
        <p><br></br></p>
        This privacy notice discloses the privacy practices for (hedgefundpages.com). 
        This privacy notice applies solely to information collected by this website:
        It will notify you of the following:
        What personally identifiable information is collected from you through the website, how it is used and with whom it may be shared.
        - None. No login/email required. 
        What choices are available to you regarding the use of your data.
        - You can email us if you so choose.
        
        Information Collection, Use, and Sharing 
        We are the sole owners of the information collected on this site. We only have access to/collect information that you voluntarily give us via email or other direct contact from you. 
        We will not sell or rent this information to anyone.
        If you feel that we are not abiding by this privacy policy, you should contact us immediately via our Contact page.
    </div>
)
}
}