import React from "react";


export default function Component(props) {
  const {  fundname, fundtype, grossassets, mininvestment, auditor, primebroker, custodian, administrator, domicile, fund_id, Yo1Y, Yo2Y} = props;
  //const hrefurl = 'https://www.adviserinfo.sec.gov/IAPD/IAPDFirmSummary.aspx?ORG_PK=' + crdnumber
  document.addEventListener("touchstart", function() {},false);
  
  return (

  

                   <tbody>
                              <tr>
                                <td text-info> {fundname}</td>
                                <td>${grossassets}</td>
                                  <td>{fundtype}</td>

                                  
                                  <td>{domicile}</td>
                                
                                  
                                  <td>${mininvestment}</td>
                                  <td>{Yo1Y}</td>
                              </tr>
                              <tr>   
                                  <td>{fund_id}</td>
                                  <td word-break overflow-wrap="break-word">{auditor}</td>
                               
                                 
                                  <td word-break >{primebroker}</td>
                               
                                
                                  <td overflow-wrap="break-word">{custodian}</td>
                             
                                  
                                  <td overflow-wrap="break-word">{administrator}</td>
                                  <td>{Yo2Y}</td>
                                </tr>
                      </tbody>
                      
    
 
  );
}

