import React, { Component } from 'react'
import { Link } from "react-router-dom";



export default class AdviserClass extends Component {
    constructor(props) {
      super(props);
      this.state = {
      //  gridTextAssets: [],
  
        width: window.innerWidth,
      };


     }

/*      
   componentDidMount () {
  
    console.log('in Did mount')
      console.log('in get funds', this.props.adviser);
      var rData1 = [];
      this.setState({rData: (rData1)});
      Axios.get(P2url)
      .then((response) => {
        this.setState({rData: (response.data)
             .filter(g2 => g2.Adviser.startsWith(this.props.adviser))
            })
        })
        
   } */
      
   
    addCommas(nStr) {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ',' + '$2'); //eslint-disable-line
        }
        return x1 + x2;
    }

    
 
  UNSAFE_componentWillReceiveProps () {
   // console.log('in will recieve props')
    window.addEventListener('resize', this.handleWindowSizeChange);

  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
   // console.log('in will mount')


  }
  
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  render() {

    // const { width } = this.state;
    // const isMobile = width <= 800;
    // const {
    //     rData,
    //   } = this.state;


 /*    if (isMobile) {
      return ( 

        <div class="card border-info">
            <div class="card-header">Regulatory AUM: ${this.props.regAUM} </div>
                <div class="card-body" max-width="100%">
                    <h5 class="card-title text-center text-info"><strong>{this.props.adviser}</strong></h5>
                    
                  
                            
                            <p class="card-text">
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/' + this.props.street.toString() + '+' + this.props.city.toString() + '+' + this.props.state.toString()}> 
                               <u> {this.props.street} <br></br> {this.props.city} {this.props.state} {this.props.zip} </u> </a>  <br></br> {this.props.country} <br></br></p>
                            <p>  {this.props.phone}</p> <br></br>                      
                     
                </div>

            <div class="card-footer">
                
                    {!this.props.crdnumber? 'CRD not available' : <a target="_blank" rel="noopener noreferrer" href={('https://www.adviserinfo.sec.gov/IAPD/IAPDFirmSummary.aspx?ORG_PK=' + this.props.crdnumber).toString()}>
                        <u>See Form ADV</u>  </a>}   
                        &emsp;&emsp;&emsp;&emsp;
                        &emsp;&emsp;&emsp;&emsp;
                         <a target="_blank" rel="noopener noreferrer" href={('mailto:' + this.props.email).toString()}> 
                         <i><u> {this.props.cco} </u></i> </a>  &emsp;&emsp;
                        {this.props.title ? this.props.title : 'Title Not Provided'} 
                
            </div>
        </div>

      );
    
      } else { 
*/
 
    return (
        <div class="card border-info">
            <div class="card-header">Regulatory AUM: ${this.props.regAUM}  <br></br> {this.props.adviserId}</div>
                <div class="card-body">
                    <h6 class="card-title text-center text-info"><strong>{this.props.adviser} </strong></h6> 
                         <p><br></br></p>             
                         <p class="card-text">
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/' + this.props.street.toString() + '+' + this.props.city.toString() + '+' + this.props.state.toString()}> 
                               <u> {this.props.street} <br></br> {this.props.city} {this.props.state} {this.props.zip} </u> </a> <br></br> {this.props.country} <br></br>
                               
                                 {this.props.phone}</p>

                    <Link to={{
                            pathname:"/Grid",
                            GridProps: {
                                adviser:this.props.adviser,
                                regAUM:this.props.regAUM,
                                street:this.props.street, 
                                city:this.props.city,
                                state:this.props.state,
                                zip:this.props.zip,
                                country:this.props.country,
                                phone:this.props.phone,
                                website:this.props.Website,
                                Twitter:this.props.Twitter,
                                Facebook:this.props.Facebook,
                                GoogleYouTube:this.props.GoogleYouTube,
                                Instagram:this.props.Instagram,
                                LinkedIn:this.props.LinkedIn,
                                Person1:this.props.Person1,
                                Person2:this.props.Person2,
                                Person3:this.props.Person3,
                                Person4:this.props.Person4,
                                Person5:this.props.Person5,
                                HedgeFund: this.props.HedgeFund,
                                LiquidityFund:this.props.LiquidityFund,
                                OtherPrivateFund:this.props.OtherPrivateFund,
                                PrivateEquityFund:this.props.PrivateEquityFund,
                                RealEstateFund:this.props.RealEstateFund,
                                SecuritizedAssetFund:this.props.SecuritizedAssetFund,
                                VentureCapitalFund:this.props.VentureCapitalFund,
                                crdnumber:this.props.crdnumber,
                                cco:this.props.cco,
                                title:this.props.title,
                                email:this.props.email,
                                adviserId:this.props.adviserId,
                                Yo1Y:this.props.Yo1Y,
                                Yo2Y:this.props.Yo2Y,
                                Yo3Y:this.props.Yo3Y

                            }
                        }}>
                        <button class="btn-info" title="See Details: Social Media, Private Fund(s), Control Persons"> See Details </button> 
                    </Link>
                        
                       
                </div>

            <div class="card-footer">
                         <a target="_blank" rel="noopener noreferrer" href={('mailto:' + this.props.email).toString()}> 
                         <i><u> {this.props.cco} </u></i> </a>  
                         <br></br>
              {this.props.title ? this.props.title : 'Title Not Provided'}
              <p></p>
                
            </div>
        </div>
    )

  }

}