import React, { Component } from "react";
//import AdviserComponent from "./AdviserComponent";
import AdviserClass from "./AdviserClass";
import "./adviser.module.css";


import Axios from 'axios';

const A2url = '/data/9LNME3INEM8WXRZTDSRE2';
// const A2url = '/data/adviserinfo20';

export default class Adviser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridTextAssets: [],
      isLoading: false,
      errors: null
    //  rData: [],
     // GridComponent:[]
     
    };

  
   // this.handlechange = this.handlechange.bind(this);
    this.getAdvisers = this.getAdvisers.bind(this);
   // this.getFunds = this.getFunds.bind(this);
  }
 
    /*  componentDidMount() {

     console.log('in didmount')

    Axios.get(A2url
        /* , {
       params: {
         State: 'NJ',
        } 
       } 
      )
     .then((response) => {
       this.setState({gridTextAssets: (response.data)
        .filter(g2 => g2.Adviser.startsWith('Z'))
        })
       })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      })
       ;

    
       console.log('end didmount');
   }    */    




/* 
  async getAdvisers2(gridTextAssets) {
    
    const api = Axios.create({
      baseURL: A2url
    });

    const response = await api.get();
    
    var adviserName = document.getElementById('advisername').value.toUpperCase();
    var state = document.getElementById('state').value;
    var city = document.getElementById('city').value.toUpperCase();
    var country = document.getElementById('country').value.toUpperCase();
    var aum = document.getElementById('aumrange').value;
    
     try {
      this.setState({gridTextAssets: (response.data)
        .filter(g2 => g2.C1F1_State === state
             && g2.C1F1_Country.toUpperCase() === country
            && g2.Adviser.startsWith(adviserName)
            && g2.C1F1_City.startsWith(city)
            && g2.TotalRegAUM > aum
            ),
            isLoading: true
      });
    } catch (error) {
      this.setState({ error, isLoading: false });
    }

    setTimeout(
      function() {
          this.setState({isLoading: false});
      }
      .bind(this),
      3000
  );

  }; */


  getAdvisers(gridTextAssets
    //, rData
    ) {
  
    this.setState({isLoading: true});
    var adviserName = document.getElementById('advisername').value.toUpperCase();
    var state = document.getElementById('state').value;
    var city = document.getElementById('city').value.toUpperCase();
    var country = document.getElementById('country').value.toUpperCase();
    var aum = document.getElementById('aumrange').value;
    
    //var gridTextAssets2 = [];

    //console.log('in perform Advisers', adviserName, state, city, country, aum);
    
    if(country !== 'UNITED STATES')
    {
      
      document.getElementById('state').value = '';
      state = '';
    }

    

     Axios.get(A2url
       , {
      params: {
        Advisername: adviserName,
        State: state,
        City: city,
        Country: country
       } 
      }
     )
    .then((response) => {
      this.setState({gridTextAssets: (response.data)
          .filter(g2 => g2.C1F1_State === state
               && g2.C1F1_Country.toUpperCase() === country
              && g2.Adviser.startsWith(adviserName)
              && g2.C1F1_City.startsWith(city)
              && g2.TotalRegAUM > aum
              ),
              isLoading: true
        })
      }
      )
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });

     // console.log('after filter');
   
     setTimeout(
        function() {
            this.setState({isLoading: false});
        }
        .bind(this),
        3500
      );

  return ;
  }

  /* commenting out for now 
  getFunds(rData) {
  
    var advisername = document.getElementById('advisername').value.toUpperCase();
    //var fundtype = document.getElementById('fundtype').value;
    var state = document.getElementById('state').value;
    var country = document.getElementById('country').value;

      console.log('in perform funds', advisername, state, country);
      Axios.get(P2url)
      .then((response) => {
        this.setState({rData: (response.data)
             .filter(g2 => g2.C1F1_State == state
                 && g2.C1F1_Country.ignoreCase == country.ignoreCase
                 && g2.Adviser.startsWith(advisername)
          //       && g2.Fund_Type == fundtype
            )
      //  })
        })})

    return ;
    } */
  clearForms()
  {
   // console.log('in clear 2');
    document.getElementById("advisername").value = null;
    document.getElementById("city").value = '';
    document.getElementById("state").value = 'NJ';
    document.getElementById("country").value = 'United States';
    document.getElementById('aumrange').value = '0';
  }


  addCommas(nStr) {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2'); //eslint-disable-line
    }
    return x1 + x2;
  }
  
  /* handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  } */
/*   handlechange() {
    var country = document.getElementById('country').value;
    if(country != 'United States')
    {
      document.getElementById('state').value = '';
    }
  } */

  render() {
    const {
      gridTextAssets,
      isLoading, 
   //   rData,
    } = this.state;
    return (
      <div id="mainContent">
        <div class="border border-info">
           <h4 align="center">Search for Registered Investment Advisers</h4>
           <form class="form-inline">
               
                <input id="advisername"  placeholder ="Enter Adviser name..." title="Adviser's name starts with.." type="text" size="30"/> 
              
               &emsp; 
                <input id="city" placeholder ="City"  title="City starts with.." type="text" size="30" /> 
                
                &emsp;
                <select id="state" title="Select a US State" >
                    <option value="NJ">New Jersey</option>
                    <option value="">FOR NON-US Search</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                </select>  

                &emsp;
                <select id="country" title="Select a Country, may contain typos/duplicates" >
                    <option value="United States">United States</option>
                    <option value="ARGENTINA">ARGENTINA</option>
                    <option value="AUSTRALIA">AUSTRALIA</option>
                    <option value="AUSTRIA">AUSTRIA</option>

                    <option value="BAHAMAS, THE">BAHAMAS, THE</option>

                    <option value="Bahrain">Bahrain</option>

                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="BERMUDA">BERMUDA</option>


                    <option value="Brazil">Brazil</option>

                  
                    <option value="BRITISH VIRGIN ISLANDS">BRITISH VIRGIN ISLANDS</option>
                    <option value="CANADA">CANADA</option>

                    <option value="CAYMAN ISLANDS">CAYMAN ISLANDS</option>

                    <option value="CHILE">CHILE</option>
                    <option value="CHINA">CHINA</option>

                    <option value="Colombia">Colombia</option>
                    <option value="COSTA RICA">COSTA RICA</option>
                    <option value="Croatia">Croatia</option>
                    <option value="CYPRUS">CYPRUS</option>
                    <option value="CZECH REPUBLIC">CZECH REPUBLIC</option>
                    <option value="Denmark">Denmark</option>
                    <option value="DOMINICAN REPUBLIC">DOMINICAN REPUBLIC</option>

                    <option value="Estonia">Estonia</option>

                    <option value="FINLAND">FINLAND</option>
                    <option value="France">France</option>
                    <option value="GERMANY">GERMANY</option>
                    <option value="GIBRALTAR">GIBRALTAR</option>
 
                    <option value="GREECE">GREECE</option>
                    <option value="GRENADA">GRENADA</option>

                    <option value="GUAM">GUAM</option>
                    <option value="GUERNSEY">GUERNSEY</option>
                
                    <option value="HONG KONG">HONG KONG</option>


                    <option value="HUNGARY">HUNGARY</option>
                    <option value="Iceland">Iceland</option>
                    <option value="INDIA">INDIA</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="IRELAND">IRELAND</option>
                    <option value="ISLE OF MAN">ISLE OF MAN</option>
               
                    <option value="ISRAEL">ISRAEL</option>
                    <option value="ITALY">ITALY</option>
                    <option value="JAMAICA">JAMAICA</option>
                    <option value="JAPAN">JAPAN</option>
                    <option value="Jersey">Jersey</option>

                    <option value="KOREA, SOUTH">KOREA, SOUTH</option>

                   
                    <option value="Liechtenstein">Liechtenstein</option>
            
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="MALAYSIA">MALAYSIA</option>
                    <option value="Malta">Malta</option>
            
                    <option value="MAURITIUS">MAURITIUS</option>
                    <option value="MEXICO">MEXICO</option>
         
                    <option value="MONACO">MONACO</option>
                
                    <option value="NETHERLANDS">NETHERLANDS</option>
           
                    <option value="NEW ZEALAND ">NEW ZEALAND </option>
                    <option value="NORWAY">NORWAY</option>

         
                    <option value="PANAMA">PANAMA</option>
                    <option value="PHILIPPINES">PHILIPPINES</option>
                   
                   
                    <option value="ROMANIA">ROMANIA</option>
                    <option value="RUSSIA">RUSSIA</option>
                
                    <option value="SAINT LUCIA">SAINT LUCIA</option>
        
                 
                    <option value="Singapore">Singapore</option>
                    <option value="Sint Maarten (Dutch part)">Sint Maarten (Dutch part)</option>
                    <option value="SOUTH AFRICA">SOUTH AFRICA</option>
                    <option value="Spain">Spain</option>
                    <option value="SWEDEN">SWEDEN</option>
                    <option value="SWITERZERLAND">SWITERZERLAND</option>
                    <option value="Switzerland">Switzerland</option>
                      <option value="TAIWAN, REPUBLIC OF CHINA">TAIWAN, REPUBLIC OF CHINA</option>
             

                    <option value="THAILAND">THAILAND</option>
              
                    <option value="TRINIDAD AND TOBAGO">TRINIDAD AND TOBAGO</option>
                    <option value="Turkey">Turkey</option>
      
                    <option value="Ukraine">Ukraine</option>
                    <option value="UNITED ARAB EMIRATES ">UNITED ARAB EMIRATES </option>
                    <option value="United Kingdom">United Kingdom</option>

                    <option value="United States">United States</option>
                    <option value="UNITED STATES OF AMERICA">UNITED STATES OF AMERICA</option>
                    <option value="URUGUAY">URUGUAY</option>

                    <option value="VENEZUELA">VENEZUELA</option>
                  
                </select>
                
                &emsp;
                                 
                  <select id="aumrange" title="Minimum Reg AUM">
                      <option value="0">Regulatory AUM over $0</option>
                      <option value="1000000000">$1 Billion</option>
                      <option value="10000000000">$10 Billion</option>
                      <option value="50000000000"> $50 Billion</option>
                      <option value="50000000001">$50 Billion +</option>
                  </select>

                

            {/* <input id="privatefunds" type="checkbox" />  Has Private Funds?  */}
            </form>
            <br></br>
            &emsp;
            <button class="btn btn-secondary" onClick= {(this.clearForms)} title="Resets the choices">Reset</button>
            &emsp;
            <button class="btn btn-primary" onClick= {(this.getAdvisers)} title="Get Advisers">Submit
            {isLoading? (<span class="spinner-grow spinner-grow-sm"></span>) : (<span></span>)}
            </button>
            
          
          {/* <p><br></br> */}
          &emsp; &emsp; Number of Advisers displayed: {gridTextAssets.length.toString()} 
           {/* </p> */}
          <p></p>
                
        </div>
        
        <div className="container">
        <p></p><p></p>
        {isLoading? (<span class="spinner-grow spinner-grow-lg spinner-primary"></span>) : (<span></span>)}
        
        
          <div class="card-columns">
         

              {gridTextAssets.map(textAssets => (
                <AdviserClass id="grid1"
                  key={textAssets.index}
                  adviser={textAssets.Adviser}
                  cco={textAssets.C1J_Name}
                  title={textAssets.C1J_Title}
                  phone={textAssets.Phone}
                  email={textAssets.C1J_Email}
                  crdnumber={textAssets.CRDNumber}
                  street={textAssets.C1F1_Street_1}
                  city={textAssets.C1F1_City}
                  state={textAssets.C1F1_State}
                  zip={textAssets.C1F1_Postal}
                  country={textAssets.C1F1_Country}
                  pf={textAssets.PrivateFunds}
                  regAUM={this.addCommas(textAssets.TotalRegAUM)}
                  Website={textAssets.Website}
                  Twitter={textAssets.Twitter}
                  Facebook={textAssets.Facebook}
                  GoogleYouTube={textAssets.GoogleYouTube}
                  Instagram={textAssets.Instagram}
                  LinkedIn={textAssets.LinkedIn}
                  HedgeFund={this.addCommas(textAssets.HedgeFund)}
                  LiquidityFund={this.addCommas(textAssets.LiquidityFund)}
                  OtherPrivateFund={this.addCommas(textAssets.OtherPrivateFund)}
                  PrivateEquityFund={this.addCommas(textAssets.PrivateEquityFund)}
                  RealEstateFund={this.addCommas(textAssets.RealEstateFund)}
                  SecuritizedAssetFund={this.addCommas(textAssets.SecuritizedAssetFund)}
                  VentureCapitalFund={this.addCommas(textAssets.VentureCapitalFund)}
                  Person1={textAssets.Person1}
                  Person2={textAssets.Person2}
                  Person3={textAssets.Person3}
                  Person4={textAssets.Person4}
                  Person5={textAssets.Person5}
                  adviserId={textAssets.AdviserId}
                  Yo1Y={textAssets.Yo1Y}
                  Yo2Y={textAssets.Yo2Y}
                  Yo3Y={textAssets.Yo3Y}
                /> 
              )
              )
              }
            


          </div>
        </div>


      </div>
    );
  }
}
