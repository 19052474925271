import React from "react";


export default function Component(props) {
  const {  fundname, fundtype, grossassets, mininvestment, auditor, primebroker, custodian, administrator, domicile,fund_id, Yo1Y, Yo2Y} = props;
  //const hrefurl = 'https://www.adviserinfo.sec.gov/IAPD/IAPDFirmSummary.aspx?ORG_PK=' + crdnumber
  document.addEventListener("touchstart", function() {},false);
  
  return (

    <div class="card">
    <div class="card-header">Gross Assets: ${grossassets} </div>
    <div class="card-body">
      <h6 class="card-title text-center text-info"><strong>{fundname}</strong></h6>
     {/* <p class="card-text">Fund Type: <strong>{fundtype}</strong></p>
       <p class="card-text" text-aling="left"> Auditor: &emsp;{auditor}  <br></br> Prime Broker:&emsp;{primebroker}  <br></br> Custodian: &emsp;{cutodian}  
                          <br></br> Administrator: &emsp;{administrator}</p> */}

                          <div class="table-responsive-sm">          
                            <table class="table table-borderless table-sm">
                              <tbody> 
                              <tr>
                                  <td text-secondary>Fund ID</td>
                                  <td>{fund_id}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Fund Type</td>
                                  <td>{fundtype}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Domicile</td>
                                  <td>{domicile}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Min Investment Amount</td>
                                  <td>${mininvestment}</td>
                                </tr>
                              
                              
                              <tr>
                                  <td text-secondary>Auditor</td>
                                  <td word-wrap="break-word">{auditor}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Prime Broker</td>
                                  <td word-wrap="break-word">{primebroker}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Custodian</td>
                                  <td word-wrap="break-word">{custodian}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Administrator</td>
                                  <td word-wrap="break-word">{administrator}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Yo1Y%</td>
                                  <td>{Yo1Y}</td>
                                </tr>
                                <tr>
                                  <td text-secondary>Yo2Y%</td>
                                  <td>{Yo2Y}</td>
                                </tr>

                              </tbody>
                            </table>
                            </div>

    </div>
    </div>
 
  );
}

