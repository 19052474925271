import React, { Component } from "react";
import * as emailjs from 'emailjs-com';

export default class Contact extends Component {
  
  mySubmit (event) {

    event.preventDefault();
    
   // console.log('in mySubmit')
    
    var service_id = "default_service";
    var template_id = "template_LH5nlm7n";
    var user_id = "user_vqXay0SQ4yFSLjS2cgIvt";
    //emailjs.init("user_vqXay0SQ4yFSLjS2cgIvt");
    
    //console.log('before send')
    emailjs.sendForm(service_id, template_id, '#contact-form', user_id)
    .then(function(response) {
       console.log('SUCCESS!', response.status, response.text);
    }, function(error) {
       console.log('FAILED...', error);
    });
 
   // console.log('after send')
    document.getElementById("contact-form").reset();
  }


  render() {
    return (
      
      <div>
        <h3>&emsp;COMMENTS / QUESTIONS</h3>
        <p></p>
                 
        <form id="contact-form">
            
            
            <p>&emsp;<input type="text" name="user_name" placeholder="Name" size="38"/></p>
            
            <p>&emsp;<input type="email" name="user_email" placeholder="Email" size="38"/></p>
         
            <p>&emsp;<textarea name="contact" placeholder="Please enter your comments" rows="7" cols="40"></textarea></p>
            {/* <input type="submit" value="Send" onClick={this.mySubmit}/> */}
            &emsp;
            <button class="btn btn-primary" onClick= {(this.mySubmit)} title="Send">Send</button>
        </form>




          <p><br></br></p>
          <p><br></br></p>

      </div>   

    );
  }
}