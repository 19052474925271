import React, { Component } from "react";

import GridComponent from "./GridComponent";
import WarningMessage from "../WarningMessage";

import TableComponent from "./TableComponent";

import Axios from 'axios';

const P2url = '/data/iCXmgIxFr404kQIHTSXS7LO9IK2';
// const P2url = '/data/fundinfo12';

export default class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
     // gridTextAssets: [{ description: "", header: "", id: 0 }],
      rData: [],
      WarningMessageOpen: false,
      WarningMessageText: "",
      width: window.innerWidth,
      isLoading: false,
    };

    this.handleWarningClose = this.handleWarningClose.bind(this);
  }


 
    addCommas(nStr) {
      nStr += '';
      let x = nStr.split('.');
      let x1 = x[0];
      let x2 = x.length > 1 ? '.' + x[1] : '';
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');  //eslint-disable-line
      }
      return x1 + x2;
    }
    
 
    UNSAFE_componentWillReceiveProps () {
      // console.log('in will recieve props')
      window.addEventListener('resize', this.handleWindowSizeChange);
/*       Axios.get(P2url)
      .then((response) => {
        this.setState({rData: (response.data)
              .filter(g2 => g2.Adviser.startsWith(this.props.location.GridProps.adviser))
            })
        }) */
  
    }
 
   UNSAFE_componentWillMount() {
     window.addEventListener('resize', this.handleWindowSizeChange);
    // console.log('in will mount')
/*      Axios.get(P2url)
     .then((response) => {
       this.setState({rData: (response.data)
            .filter(g2 => g2.Adviser.startsWith(this.props.location.GridProps.adviser))
           })
       }) */
 
   }


  
   // Get the text sample data from the back end
  componentDidMount() {
    Axios.get(P2url)
    .then((response) => {
      this.setState({rData: (response.data)
            .filter(g2 => g2.Adviser.startsWith(this.props.location.GridProps.adviser))
          })
      })

      setTimeout(
        function() {
            this.setState({isLoading: false});
        }
        .bind(this),
        1500
      );
  } 

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };

  handleWarningClose() {
    this.setState({
      WarningMessageOpen: false,
      WarningMessageText: ""
    });
  }

render() {
    const {
      rData,
      WarningMessageOpen,
      WarningMessageText,
      width,
      isLoading, 
    } = this.state;
    
     const isMobile = width <= 800;


  if (!isMobile) 
  {
    return (
      <main id="mainContent">
        <div className="container">
           <div class="card border-info">
            <div class="card-header">
                      {isLoading? (<span class="spinner-grow text-primary spinner-grow-sm"></span>) : (<span></span>)}
                      
                      Regulatory AUM: ${this.props.location.GridProps.regAUM} &emsp;  &emsp;
                      YoY: {this.props.location.GridProps.Yo1Y}/{this.props.location.GridProps.Yo2Y}/{this.props.location.GridProps.Yo3Y}
                      &emsp; &emsp; &emsp; Number of Private Funds: 
                      {rData.length.toString()}  &emsp; &emsp;{this.props.location.GridProps.adviserId}
                      
              </div>
                <div class="card-body">
                    <h5 class="card-title text-center text-info"><strong>{this.props.location.GridProps.adviser}</strong></h5>
                    
                    <div class="row">
                        <div class="col-3 border">
                            <p class="card-text">
                            <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/' + this.props.location.GridProps.street 
                                    + '+' + this.props.location.GridProps.city + '+' + this.props.location.GridProps.state}> 
                                  <u> {this.props.location.GridProps.street} <br></br> {this.props.location.GridProps.city} {this.props.location.GridProps.state} 
                                  {this.props.location.GridProps.zip} </u> </a> <br></br> {this.props.location.GridProps.country} <br></br>
                                 {this.props.location.GridProps.phone}</p>
                          <div class="border">
                            <p class="card-text"> {(this.props.location.GridProps.website === 'NA')? 'Website Not Provided' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.website}>
                                <u>See Website</u></a> } </p> 
                            <p class="card-text"> {(this.props.location.GridProps.Twitter === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Twitter}>
                                <u>Twitter</u></a> }</p>
                            <p class="card-text"> {(this.props.location.GridProps.Facebook === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Facebook}>
                                <u>Facebook</u></a> }</p>
                            <p class="card-text"> {(this.props.location.GridProps.GoogleYouTube === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.GoogleYouTube}>
                                <u>GoogleYouTube</u></a> } </p>
                            <p class="card-text"> {(this.props.location.GridProps.Instagram === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Instagram}>
                                <u>Instagram</u></a> }</p>
                            <p class="card-text">{(this.props.location.GridProps.LinkedIn === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.LinkedIn}>
                                <u>LinkedIn</u></a> }</p>
                          </div>
                        </div>
                        <div class="col-5 border">
                            <p> {this.props.location.GridProps.Person1} </p>
                           <p>{this.props.location.GridProps.Person2}</p>
                           <p>{this.props.location.GridProps.Person3}</p>
                           <p>{this.props.location.GridProps.Person4}</p>
                           <p>{this.props.location.GridProps.Person5}</p>

                        </div>
                        <div class="col-4 border">
                            <p class="card-text"> Hedge Funds: ${this.props.location.GridProps.HedgeFund}</p>
                            <p class="card-text"> Liquidity Funds: ${this.props.location.GridProps.LiquidityFund}</p>
                            <p class="card-text"> Other Private Funds: ${this.props.location.GridProps.OtherPrivateFund}</p>
                            <p class="card-text"> Private Equity Funds: ${this.props.location.GridProps.PrivateEquityFund}</p>
                            <p class="card-text"> Real Estate Funds: ${this.props.location.GridProps.RealEstateFund}</p>
                            <p class="card-text"> Securitized Asset Funds: ${this.props.location.GridProps.SecuritizedAssetFund} </p>
                            <p class="card-text"> Venture Capital Funds: ${this.props.location.GridProps.VentureCapitalFund}</p>
                        </div>
                    </div>
                </div>
{/*             <div class="card-columns">
                {rData.map(textAssets => (
                    <GridComponent id="grid2"
                        key={textAssets.index}
                        fundname={textAssets.Fund_Name}
                        fundtype={textAssets.Fund_Type}
                        grossassets={this.addCommas(textAssets.Gross_Asset_Value)}
                        mininvestment = {this.addCommas(textAssets.Minimum_Investment)}
                        auditor={textAssets.Auditor}
                        primebroker={textAssets.PrimeBroker}
                        custodian={textAssets.Custodian}
                        administrator={textAssets.Administrator}
                        domicile={textAssets.Domicile}
                        />
                    ))}
                </div> */}

                

              <table class="table table-sm table-striped" table-layout="fixed">
              <tbody>
                 <tr>
                    <th>FundName</th>
                    <th>Gross Assets</th>
                    <th>FundType</th>
                    <th>Domicile</th>
                    <th>Min. Invesmtment</th>
                    <th>Yo1Y%</th>
                  </tr> 
                  <tr>
                    <th>FundId</th>
                    <th>Auditor</th>
                    <th>Custodian</th>
                    <th>Prime Broker</th>
                    <th>Administrator</th> 
                    <th>Yo2Y%</th>
                  </tr> 
                  </tbody>
                    {rData.map(textAssets => (
                        <TableComponent id="grid3"
                            key={textAssets.index}
                            fundname={textAssets.Fund_Name}
                            fundtype={textAssets.Fund_Type}
                            grossassets={this.addCommas(textAssets.Gross_Asset_Value)}
                            mininvestment = {this.addCommas(textAssets.Minimum_Investment)}
                            auditor={textAssets.Auditor}
                            primebroker={textAssets.PrimeBroker}
                            custodian={textAssets.Custodian}
                            administrator={textAssets.Administrator}
                            domicile={textAssets.Domicile}
                            fund_id={textAssets.Fund_ID}
                            Yo1Y={textAssets.Yo1Y}
                            Yo2Y={textAssets.Yo2Y}
                            Yo3Y={textAssets.Yo3Y}
                            />
                    ))}
                
              </table>

            <div class="card-footer">
                
              {!this.props.location.GridProps.crdnumber ? 'CRD not available' :
                <a target="_blank" rel="noopener noreferrer"
                  href={('https://adviserinfo.sec.gov/firm/summary/' + this.props.location.GridProps.crdnumber)}>
                        <u>See Form ADV</u>  </a>}   
                        &emsp;&emsp;&emsp;&emsp;
                        &emsp;&emsp;&emsp;&emsp;
                         <a target="_blank" rel="noopener noreferrer" href={('mailto:' + this.props.location.GridProps.email)}> 
                         <i><u> {this.props.location.GridProps.cco} </u></i> </a>  &emsp;&emsp;
                        {this.props.location.GridProps.title ? this.props.location.GridProps.title : 'Title Not Provided'} 
                
            </div>
        </div>
          <p><br></br></p>
      </div>
        <WarningMessage
          open={WarningMessageOpen}
          text={WarningMessageText}
          onWarningClose={this.handleWarningClose}
        />

      </main>
    )
  }
  else {

    return (
      <main id="mainContent">
        <div className="container">
          <div class="card border-info">
            <div class="card-header">Regulatory AUM: ${this.props.location.GridProps.regAUM} 
                <br></br> YoY: {this.props.location.GridProps.Yo1Y}/{this.props.location.GridProps.Yo2Y}/{this.props.location.GridProps.Yo3Y}
                <br></br> Number of Private Funds: {rData.length.toString()} <br></br> {this.props.location.GridProps.adviserId} </div>
            <div class="card-body">
              <h5 class="card-title text-center text-info"><strong>{this.props.location.GridProps.adviser}</strong></h5>
                <p class="card-text">
                  <a target="_blank" rel="noopener noreferrer" href={'https://www.google.com/maps/place/' + this.props.location.GridProps.street + '+' + this.props.location.GridProps.city + '+' + this.props.location.GridProps.state}> 
                  <u> {this.props.location.GridProps.street} <br></br> {this.props.location.GridProps.city} {this.props.location.GridProps.state} {this.props.location.GridProps.zip} </u> </a> <br></br> {this.props.location.GridProps.country} <br></br>
                  {this.props.location.GridProps.phone}
                </p>
                <div class="border">
                    <p class="card-text"> {(this.props.location.GridProps.website === 'NA')? 'Website Not Provided' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.website}>
                      <u>See Website</u></a> } 
                      <br></br>
                     {(this.props.location.GridProps.Twitter === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Twitter}>
                      <u>Twitter</u></a> }
                      <br></br>
                     {(this.props.location.GridProps.Facebook === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Facebook}>
                      <u>Facebook</u></a> }
                      <br></br>
                     {(this.props.location.GridProps.GoogleYouTube === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.GoogleYouTube}>
                      <u>GoogleYouTube</u></a> } 
                      <br></br>
                     {(this.props.location.GridProps.Instagram === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.Instagram}>
                      <u>Instagram</u></a> }
                      <br></br>
                    {(this.props.location.GridProps.LinkedIn === 'NA')? '' : <a target="_blank" rel="noopener noreferrer" href={this.props.location.GridProps.LinkedIn}>
                      <u>LinkedIn</u></a> }
                    </p>
                </div>
                <div class="border">
                    <p> {this.props.location.GridProps.Person1} <br></br>
                    {this.props.location.GridProps.Person2}<br></br>
                    {this.props.location.GridProps.Person3}<br></br>
                    {this.props.location.GridProps.Person4}<br></br>
                    {this.props.location.GridProps.Person5}</p>
                </div>
                <div class="border">
                    <p class="card-text"> Hedge Funds: ${this.props.location.GridProps.HedgeFund}<br></br>
                     Liquidity Funds: ${this.props.location.GridProps.LiquidityFund}<br></br>
                     Other Private Funds: ${this.props.location.GridProps.OtherPrivateFund}<br></br>
                     Private Equity Funds: ${this.props.location.GridProps.PrivateEquityFund}<br></br>
                     Real Estate Funds: ${this.props.location.GridProps.RealEstateFund}<br></br>
                     Securitized Asset Funds: ${this.props.location.GridProps.SecuritizedAssetFund} <br></br>
                     Venture Capital Funds: ${this.props.location.GridProps.VentureCapitalFund}</p>
                </div>
                {rData.map(textAssets => (
                  <GridComponent id="grid2"
                    key={textAssets.index}
                    fundname={textAssets.Fund_Name}
                    fundtype={textAssets.Fund_Type}
                    grossassets={this.addCommas(textAssets.Gross_Asset_Value)}
                    mininvestment = {this.addCommas(textAssets.Minimum_Investment)}
                    auditor={textAssets.Auditor}
                    primebroker={textAssets.PrimeBroker}
                    custodian={textAssets.Custodian}
                    administrator={textAssets.Administrator}
                    domicile={textAssets.Domicile}
                    fund_id={textAssets.Fund_ID}
                    Yo1Y={textAssets.Yo1Y}
                    Yo2Y={textAssets.Yo2Y}
                    Yo3Y={textAssets.Yo3Y}
                  />
                ))}
            </div>
          <div class="card-footer">
            {!this.props.location.GridProps.crdnumber? 'CRD not available' : <a target="_blank" rel="noopener noreferrer" href={('https://adviserinfo.sec.gov/firm/summary/' + this.props.location.GridProps.crdnumber)}>
              <u>See Form ADV</u> </a>} 
            <a target="_blank" rel="noopener noreferrer" href={('mailto:' + this.props.location.GridProps.email)}> 
              <i><u> {this.props.location.GridProps.cco} </u></i> </a> 
            {this.props.location.GridProps.title ? this.props.location.GridProps.title : 'Title Not Provided'} 
          </div>
      </div>
        <p><br></br></p>
    </div>
      <WarningMessage
        open={WarningMessageOpen}
        text={WarningMessageText}
        onWarningClose={this.handleWarningClose}
      />
  </main>
    );

  } 
}
}
